import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import banner from './assets/high-res-banner.png'

import './LiveView.css';

const HOST_IP = process.env.REACT_APP_HOST_IP; 
const API_PORT = process.env.REACT_APP_API_PORT;
const DUE_THRESH = process.env.REACT_APP_DUE_THRESH;
const NEW_THRESH = process.env.REACT_APP_NEW_THRESH;
const TIMEZONE = process.env.REACT_APP_TIMEZONE

function RoomActivityCard(room, time, name, role, isDue, isNew) {
  let bgcolor = '';
  let textcolor = '';
  let isAlert = false;

  if (isDue){
    bgcolor = 'danger';
    textcolor = 'white';
  }
  else if (isNew){
    bgcolor = 'success';
    textcolor = 'white';
  }
  else { //alert! about to turn red
    bgcolor = 'warning';
    textcolor = 'dark';
    isAlert = true;
  }

  return (
    <Col lg={2} xl={2} xxl={2}>
      <Card bg={bgcolor} text={textcolor} className={isAlert ? "blink_me" : ""}>
        <Card.Header as="small" style={{ padding: '5px' }}><b>{room}</b></Card.Header>
        <Card.Body as="small" style={{ padding: '5px' }}>{time}</Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item as="small" style={{ padding: '5px' }}>{name}</ListGroup.Item>
          <ListGroup.Item as="small" style={{ padding: '5px' }}>{role}</ListGroup.Item>
        </ListGroup>
      </Card>
    </Col>
  );
}

function generateCards(data) {
  return (
    data?.map((activity) => 
      RoomActivityCard(activity.room, activity.time, activity.name, activity.role, activity.isDue, activity.isNew))
  );
}

function RoomActivityGrid(props) {
  const [unitActivityData, updateData] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      fetch(`https://${HOST_IP}/db-heartbeat`).then(response => {
      //fetch(`http://${HOST_IP}:${API_PORT}/db-heartbeat`).then(response => {
        if (!response.ok) {
          console.log(`db-heartbeat flatline`);
          updateData(() => {});
        } else {
          fetch(`https://${HOST_IP}/last-beacon-activity`).then(response => {
          //fetch(`http://${HOST_IP}:${API_PORT}/last-beacon-activity`).then(response => {
            //convert string to JSON
            response.json().then(res => {
              //convert into array
              let data = JSON.parse(res);
              const infos = Object.values(data);
              const unitActivity = infos.map(function(info){
                let MILLIS_currentTime = Date.now();
                let MILLIS_eventTime = info["timestamp"];
                let MILLIS_delta = MILLIS_currentTime - MILLIS_eventTime;
                let currentTime = new Date(MILLIS_eventTime); 
                let prettyTime = currentTime.toLocaleTimeString('en-US');
    
                return {
                  "room": info["room"],
                  "time": prettyTime,
                  "name": info["name"],
                  "role": info["role"],
                  "isDue": MILLIS_delta > props.timeDue,
                  "isNew": MILLIS_delta < props.timeNew
                }
              });
    
              //filter to only show rooms for specific unit
              data = unitActivity.filter(val => val["room"].startsWith(props.unitPrefix));
              updateData(data.sort(function (a, b) {
                if (a.room < b.room) {
                  return -1;
                }
                if (a.room > b.room) {
                  return 1;
                }
                return 0;
              }));
            });
          }).catch((error) => {
            console.log(`failed to fetch last beacon activity: ${error.message}`);
            updateData(() => {});
          });
        }
      }).catch((error) => {
        console.log(`failed to fetch db-heartbeat: ${error.message}`);
        updateData(() => {});
      });

    }, 1000);
    return () => clearInterval(interval);

  }, [props]);

  var dataNotEmpty = true;
  try {
    dataNotEmpty = Object.keys(unitActivityData).length > 0;
  } catch(error) {
    dataNotEmpty = false;
  }

  if (dataNotEmpty) {
    return(
      <Container fluid className="p-4">
        <Row xs="1" md="2" lg="3" xl="4" xxl="5" className="g-4">
          {generateCards(unitActivityData)}
        </Row>
      </Container>
    );
  } else {
    return(
      <Container fluid>
        <Row>
          <Col>Error: Contact system administrator</Col>
        </Row>
      </Container>
    );
  }
  
}

function Clock(props) {
  const [clockData, updateData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log("tick v1.1");
      updateData(() => {
        let MILLIS_currentTime = Date.now();
        let currentTime = new Date(MILLIS_currentTime);
        let prettyTime = currentTime.toLocaleTimeString('en-US');
        return prettyTime;
      });
      
    }, 1000);

    return () => clearInterval(interval);
  }, [props]);

  return(
    <Navbar.Text as="h4">
      {clockData}
    </Navbar.Text>
  );
  
}

function NavbarHeader(props) {
  return (
    <Navbar sticky="top" bg="light" expand="lg" className="bg-body-tertiary">
        <Navbar.Brand href="#home">
              <img
                src={banner} 
                style={{ maxHeight: '50px' }}
                className="d-inline-block align-top"
                alt="Intelligent Observation"
              />
        </Navbar.Brand>
        <Clock />

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">

          <UnitDropdown onSetUnit={props.onSetUnit}/>
          <Navbar.Text as="h4" style={{ padding: '10px' }}> {props.unitPrefix} </Navbar.Text>
          
        </Navbar.Collapse>
    </Navbar>
  );
}

function UnitDropdown({onSetUnit}) {
  return (
    <NavDropdown title="Select Unit" id="basic-nav-dropdown" style={{ padding: '10px' }}>
      <NavDropdown.Item onClick={onSetUnit}>A1O</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>A2E PEDS</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>A2E PICU</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>A3E</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>A5E</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>A5S</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B2 L&D</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B2 MATERNITY</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B2 NICU</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B3</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B4 NS</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B4 ST</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B5</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B6 BMT</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>B6 ONCOLOGY</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>CVICU</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>K2 NICU</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>K3</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>M5</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>M6</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>M7</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>NW2</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>NW5</NavDropdown.Item>
      <NavDropdown.Item onClick={onSetUnit}>NW6</NavDropdown.Item>
    </NavDropdown>
  );
}

export default function LiveView(props) {
  const location = useLocation();
  const unitParam = new URLSearchParams(location.search).get('unit');  

  var unit;
  if (unitParam) {
    unit = unitParam;
  } else {
    unit = props.unit;
  }

  const [unitPrefix, setUnit] = useState(unit);

  function handleChange(e) {
    setUnit(e.target.innerText);
  }
  
  return(
    <Container fluid>
      <NavbarHeader 
        unitPrefix={unitPrefix}
        onSetUnit={handleChange}/>
      
      <RoomActivityGrid 
        unitPrefix={unitPrefix}
        timeDue={DUE_THRESH}
        timeNew={NEW_THRESH}/>
    </Container>
  );
}
